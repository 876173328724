/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { IThreeDViewerConfig } from '../../models/IThreeDViewerConfig';
import { IThreeDViewerState} from '../../models/IThreeDViewerState';
import { Toolbox } from './Measurement/ToolBox/Toolbox';
interface IProps {
    config: IThreeDViewerConfig | null;
    getViewerStateFlag?: number;
    viewerState: IThreeDViewerState | null;
    onGetViewerState?: Function;
}
export function ThreeDViewer(props: IProps) {

    const [viewer, setViewer] = useState<any|null>(null);

    // FYI This Cesium is a global object loaded via js file in index.html as there were compilation issues with Cesium from Npm.
    let Cesium = (window as any).Cesium;
    
    Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJiOTViYzJmZi0xMzFjLTQ1MjgtYmU1MC02YTNhMjdlNmUyZjgiLCJpZCI6MTk1MzAxLCJpYXQiOjE3MDc4MDQzMTR9.JGMA5rr8MQ9cKap5rZoTDjnT_GM0xyfrEKQZNAIKJmU';

    useEffect(() => {

        let theViewer = viewer;
        if ( theViewer === null )
        {
            theViewer = new Cesium.Viewer("cesiumContainer", {
              infoBox: false,
              selectionIndicator: false,
              shadows: true,
              shouldAnimate: true,
              animation: false,
              fullscreenButton: false,
              geocoder: false,
              timeline: false,
              homeButton: false,
              sceneModePicker: false,
              scene3DOnly: true, //saves memory
              creditContainer: "credits",
              creditViewport: "credits"
            });

            setViewer(theViewer);
        }

        if ( props.config != null )
        {
            let height = 30;
            flyTo(props.config.location.latitude, props.config.location.longitude, height, theViewer);
          

            //TODO call Api to get url for model and load it

            //let url = await getmodelforsegment(props.config.segmentId)
            //if url
            //  loadModel(url,props.config.location.latitude, props.config.location.longitude, height);
        }

    }, [props.config, viewer]);

    useEffect(() => {
           
        if (viewer && props.onGetViewerState && props.getViewerStateFlag && props.getViewerStateFlag > 0 && props.config ){
            
            // Return the current viewer state so it can be popped out

            props.onGetViewerState( { 
                segmentId: props.config.segmentId,
                cameraPitch: viewer.camera.pitch,
                cameraHeading: viewer.camera.heading,
                cameraPosition: viewer.camera.position,            
            }  );
        }

    }, [props.getViewerStateFlag, viewer]);
    
    useEffect(() => {

        if ( props.viewerState && viewer )
        {
            // The viewer has been popped out. Restore its state

            viewer.camera.flyTo({
                destination: props.viewerState.cameraPosition,
                orientation: {
                    heading: props.viewerState.cameraHeading,
                    pitch: props.viewerState.cameraPitch,
                }});

            // TODO call api to load model

        }
    }, [props.viewerState, viewer]);

    function flyTo( latitude: number, longitude: number, height: number, viewer: any) {

        viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
                longitude,
                latitude,
                height
            ),
            orientation: {
                heading: Cesium.Math.toRadians(0.0),
                pitch: Cesium.Math.toRadians(-15.0),
            }});
    }

    function loadModel(url: string, latitude: number, longitude: number, height: number) {

      viewer.entities.removeAll();

      const position = Cesium.Cartesian3.fromDegrees(
        longitude,
        latitude,
        height
      );
      const heading = Cesium.Math.toRadians(135);
      const pitch = 0;
      const roll = 0;
      const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
      const orientation = Cesium.Transforms.headingPitchRollQuaternion(
        position,
        hpr
      );

      const entity = viewer.entities.add({
        name: url,
        position: position,
        orientation: orientation,
        model: {
          uri: url,
          minimumPixelSize: 128,
          maximumScale: 20000,
        },
      });
      viewer.trackedEntity = entity;
    }

    return (<div style={{height: '100%', width:'100%'}}>
        <div id="cesiumContainer" style={{ height: '100%', width: '100%' }}></div>
        <div id="measurementToolbox" style={{ position: 'absolute', bottom: '10px', left: '10px' }}>
            <Toolbox viewer={viewer} ></Toolbox>
        </div>
        <div id="credits" style={{display: 'none',height:0,width:0}}>div</div>      
     </div> );
}
