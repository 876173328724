/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Distance } from '../Distance/Distance';
import { Area } from '../Area/Area';
import DistanceIcon from '../../../../images/line.png';
import AreaIcon from '../../../../images/polygon.png';
import styles from "./ToolBox.module.css";
interface IProps {
    viewer: any; 
}
export function Toolbox(props: IProps) {
    
    const [isDistanceModeOn, setIsDistanceModeOn] = useState<boolean>(false);
    const [isAreaModeOn, setIsAreaModeOn] = useState<boolean>(false);
    const [area, setArea] = useState<string>('0.0');

    const displayArea = (area: any) => {
        setArea(area);
    }

    return (
        <div className="toolbox">
            <button
                onClick={() => { setIsDistanceModeOn(!isDistanceModeOn); }}
                disabled={isAreaModeOn}
                style={{ border: isDistanceModeOn ? '2px solid red' : '1px solid #FCCC0D' }}
                className={styles.toolButton}
            >
                {<img src={DistanceIcon}
                    className={styles.toolIcon}
                    alt="Distance"
                    aria-hidden="true"
                />}
            </button>
            <button
                onClick={() => { setIsAreaModeOn(!isAreaModeOn); }}
                disabled={isDistanceModeOn}
                style={{ border: isAreaModeOn ? '2px solid red' : '1px solid #FCCC0D' }}
                className={styles.toolButton}
            >
                <img src={AreaIcon}
                    className={styles.toolIcon}
                    alt="Area"
                    aria-hidden="true"
                />
            </button>
            {area !== '0.0' && <span className={ styles.areaText }>Area = {area}</span>}
           
            {props.viewer && <Distance viewer={props.viewer} isDistanceModeOn={isDistanceModeOn} />}
            {props.viewer && <Area viewer={props.viewer} isAreaModeOn={isAreaModeOn} displayArea={displayArea} />}
        </div>
    );
}

