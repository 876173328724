/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

interface IProps {
    viewer: any;
    isDistanceModeOn: boolean;

}
export function Distance(props: IProps) {
    // FYI This Cesium is a global object loaded via js file in index.html as there were compilation issues with Cesium from Npm.
    let Cesium = (window as any).Cesium;

    const [points, setPoints] = useState<any>(null);
    const [polylines, setPolylines] = useState<any>(null);
    const [handler, setHandler] = useState<any>(null);
    const [scene, setScene] = useState<any>(null);

    const [distanceLabelEntity, setDistanceLabelEntity] = useState<any>(null);
    const [horizontalLabelEntity, setHorizontalLabelEntity] = useState<any>(null);
    const [verticalLabelEntity, setVerticalLabelEntity] = useState<any>(null);
    const [angleALabelEntity, setAngleALabelEntity] = useState<any>(null);
    const [angleBLabelEntity, setAngleBLabelEntity] = useState<any>(null);
   
    let horizontalSide: any, verticalSide: any, hypotenuse: any;
    let point1: any, point2: any;
    let point1GeoPosition: any, point2GeoPosition: any;
    let geodesic = new Cesium.EllipsoidGeodesic();

    const LINEPOINTCOLOR = Cesium.Color.RED;
    const Ellipsoid = Cesium.Ellipsoid.WGS84;


    let label = {
        font: '14px monospace',
        showBackground: true,
        verticalOrigin: Cesium.VerticalOrigin.CENTER,
        pixelOffset: new Cesium.Cartesian2(0, 0),
        eyeOffset: new Cesium.Cartesian3(0, 0, -50),
        fillColor: Cesium.Color.WHITE,
        text: ""
    };


    useEffect(() => {
        if (props.isDistanceModeOn) {
            setPoints(new Cesium.PointPrimitiveCollection());
            setPolylines(new Cesium.PolylineCollection());
            setScene(props.viewer.scene);
            setHandler(new Cesium.ScreenSpaceEventHandler(props.viewer.scene.canvas));
        }
        else {
            clearMeasurement();
            handler && handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        }
    }, [props.isDistanceModeOn]);

    useEffect(() => {
       
        if (props.isDistanceModeOn && scene != null && handler != null) {
         
            scene.primitives.add(polylines);
            scene.primitives.add(points);
     
            handler.setInputAction((click: any) => {
                if (scene.mode !== Cesium.SceneMode.MORPHING) {

                    //The below condition ensures that we hit an actual model. Commenting it for now. Once we have models loaded, they can be uncommented.

                    // var pickedObject = scene.pick(click.position);
                    //if (scene.pickPositionSupported && Cesium.defined(pickedObject)) {
                    var cartesian = props.viewer.scene.pickPosition(click.position);
                    
                    if (Cesium.defined(cartesian)) {
                        //add first point
                        if (points.length === 0) {
                            point1 = points.add({
                                position: new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z),
                                color: LINEPOINTCOLOR
                            });
                        } //add second point and lines
                        else if (points.length === 1) {
                            point2 = points.add({
                                position: new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z),
                                color: LINEPOINTCOLOR
                            });
                            point1GeoPosition = Cesium.Cartographic.fromCartesian(point1.position);
                            point2GeoPosition = Cesium.Cartographic.fromCartesian(point2.position);

                            var pl1Positions = [
                                new Cesium.Cartesian3.fromRadians(point1GeoPosition.longitude, point1GeoPosition.latitude, point1GeoPosition.height),
                                new Cesium.Cartesian3.fromRadians(point2GeoPosition.longitude, point2GeoPosition.latitude, point2GeoPosition.height)
                            ];
                            var pl2Positions = [
                                new Cesium.Cartesian3.fromRadians(point2GeoPosition.longitude, point2GeoPosition.latitude, point2GeoPosition.height),
                                new Cesium.Cartesian3.fromRadians(point2GeoPosition.longitude, point2GeoPosition.latitude, point1GeoPosition.height)
                            ];
                            var pl3Positions = [
                                new Cesium.Cartesian3.fromRadians(point1GeoPosition.longitude, point1GeoPosition.latitude, point1GeoPosition.height),
                                new Cesium.Cartesian3.fromRadians(point2GeoPosition.longitude, point2GeoPosition.latitude, point1GeoPosition.height)
                            ];

                            polylines.add({
                                show: true,
                                positions: pl1Positions,
                                width: 3,
                                material: new Cesium.Material({
                                    fabric: {
                                        type: 'Color',
                                        uniforms: {
                                            color: LINEPOINTCOLOR
                                        }
                                    }
                                })
                            });
                             polylines.add({
                                show: true,
                                positions: pl2Positions,
                                width: 3,
                                material: new Cesium.Material({
                                    fabric: {
                                        type: 'PolylineDash',
                                        uniforms: {
                                            color: LINEPOINTCOLOR,
                                        }
                                    },
                                })
                            });
                            polylines.add({
                                show: true,
                                positions: pl3Positions,
                                width: 3,
                                material: new Cesium.Material({
                                    fabric: {
                                        type: 'PolylineDash',
                                        uniforms: {
                                            color: LINEPOINTCOLOR,
                                        }
                                    },
                                })
                            });
                            var labelZ;
                            if (point2GeoPosition.height >= point1GeoPosition.height) {
                                labelZ = point1GeoPosition.height + (point2GeoPosition.height - point1GeoPosition.height) / 2.0;
                            } else {
                                labelZ = point2GeoPosition.height + (point1GeoPosition.height - point2GeoPosition.height) / 2.0;
                            };

                            addDistanceLabel(point1, point2, labelZ);

                            //Calculate angles 
                            var angleA = Math.asin(horizontalSide / hypotenuse);
                            var angleB = Math.asin(verticalSide / hypotenuse);

                            // Add a label to display the angle
                            addAngleLabels(angleA, angleB, point1GeoPosition, point2GeoPosition);
                            handler && handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
                        }
                    }
                    // }
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        }
    }, [handler, scene, props.isDistanceModeOn]);

    function addDistanceLabel(point1:any, point2:any, height:any) {
        point1.cartographic = Ellipsoid.cartesianToCartographic(point1.position);
        point2.cartographic = Ellipsoid.cartesianToCartographic(point2.position);
        point1.longitude = parseFloat(Cesium.Math.toDegrees(point1.position.x));
        point1.latitude = parseFloat(Cesium.Math.toDegrees(point1.position.y));
        point2.longitude = parseFloat(Cesium.Math.toDegrees(point2.position.x));
        point2.latitude = parseFloat(Cesium.Math.toDegrees(point2.position.y));
        label.text = getHorizontalDistanceString(point1, point2);

        setHorizontalLabelEntity(props.viewer.entities.add({
            position: getMidpoint(point1, point2, point1GeoPosition.height),
            label: label
        }));
        label.text = getDistanceString(point1, point2);
        setDistanceLabelEntity(props.viewer.entities.add({
            position: getMidpoint(point1, point2, height),
            label: label
        }));
        label.text = getVerticalDistanceString();
        setVerticalLabelEntity(props.viewer.entities.add({
            position: getMidpoint(point2, point2, height),
            label: label
        }));
    };

    function getHorizontalDistanceString(point1:any, point2:any) {
        geodesic.setEndPoints(point1.cartographic, point2.cartographic);
        horizontalSide = geodesic.surfaceDistance.toFixed(2);
        if (horizontalSide >= 1000) {
            return (horizontalSide / 1000).toFixed(1) + ' KM';
        }
        return horizontalSide + ' M';
    };

    function getVerticalDistanceString() {
        var heights = [point1GeoPosition.height, point2GeoPosition.height];
        verticalSide = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
        if (verticalSide >= 1000) {
            return (verticalSide / 1000).toFixed(1) + ' KM';
        }
        return verticalSide.toFixed(2) + ' M';
    };

    function getDistanceString(point1:any, point2:any) {
        geodesic.setEndPoints(point1.cartographic, point2.cartographic);
        var horizontalMeters = geodesic.surfaceDistance.toFixed(2);
        var heights = [point1GeoPosition.height, point2GeoPosition.height];
        var verticalMeters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
        hypotenuse = Math.pow((Math.pow(horizontalMeters, 2) + Math.pow(verticalMeters, 2)), 0.5);

        if (hypotenuse >= 1000) {
            return (hypotenuse / 1000).toFixed(1) + ' KM';
        }
        return hypotenuse.toFixed(2) + ' M';
    };

    function getMidpoint(point1: any, point2: any, height: any) {
        var scratch = new Cesium.Cartographic();
        geodesic.setEndPoints(point1.cartographic, point2.cartographic);
        var midpointCartographic = geodesic.interpolateUsingFraction(0.5, scratch);
        return Cesium.Cartesian3.fromRadians(midpointCartographic.longitude, midpointCartographic.latitude, height);
    };

    function addAngleLabels(angleA: any, angleB: any, point1: any, point2: any) {
        //Should display the angle opposite to the side which means it is near the other point
        //angle opposite to horizontalSide
        setAngleALabelEntity ( props.viewer.entities.add({
            position: Cesium.Cartesian3.fromRadians(point2.longitude, point2.latitude, point2.height),
            label: {
                text: Cesium.Math.toDegrees(angleA).toFixed(2) + "\u00B0",
                font: '14px monospace',
                fillColor: Cesium.Color.YELLOW,
                outlineColor: Cesium.Color.BLACK,
                outlineWidth: 2,
                style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                pixelOffset: new Cesium.Cartesian2(0, -20)
            }
        }));
        //angle opposite to verticalSide
        setAngleBLabelEntity(props.viewer.entities.add({
            position: Cesium.Cartesian3.fromRadians(point1.longitude, point1.latitude, point1.height),
            label: {
                text: Cesium.Math.toDegrees(angleB).toFixed(2) + "\u00B0",
                font: '14px monospace',
                fillColor: Cesium.Color.YELLOW,
                outlineColor: Cesium.Color.BLACK,
                outlineWidth: 2,
                style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                pixelOffset: new Cesium.Cartesian2(0, -20)
            }
        }));
    }
    
    function clearMeasurement() {
        if (distanceLabelEntity) {
            props.viewer.entities.remove(distanceLabelEntity);
            setDistanceLabelEntity(null);
        }
        if (horizontalLabelEntity) {
            props.viewer.entities.remove(horizontalLabelEntity);
            setHorizontalLabelEntity(null);
        }
        if (verticalLabelEntity) {
            props.viewer.entities.remove(verticalLabelEntity);
            setVerticalLabelEntity(null);
        }
        if (angleALabelEntity) {
            props.viewer.entities.remove(angleALabelEntity);
            setAngleALabelEntity(null);
        }
        if (angleBLabelEntity) {
            props.viewer.entities.remove(angleBLabelEntity);
            setAngleBLabelEntity(null);
        }
       
        if (polylines) polylines.removeAll();

        if (points) points.removeAll();

        setPoints([]);
        handler && handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        handler && handler.destroy();
        setHandler(null);
    }

    return (
        <div></div>
    );
}
